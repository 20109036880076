$page-gutter: 48;

@media (min-width: $b-p-desktop) {
    .icon {
        &--search {
            width: 16px;
            height: 16px;
            vertical-align: -1px;
        }
    }

    .standard-page-gutter,
    .standard-page-gutter:not(.full-mobile) {
        padding: 0 rem($page-gutter);
        max-width: 1348px;
        margin-left: auto;
        margin-right: auto;

        &.no-max {
            max-width: none;
        }
    }

    .pseudo-header {
        height: 9rem;
    }

    .nav {
        margin: rem(36) auto;
        .standard-page-gutter {
            transition: max-width 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s,
                padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &__inner {
            flex-flow: row;
        }

        &__list {
            justify-content: flex-end;
            padding: 0;
            margin-right: -1rem;
            margin-left: -1rem;
            width: auto;
        }

        &.home--ready {
            .standard-page-gutter {
                max-width: 4000px;
                transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }

        &.home {
            .standard-page-gutter {
                padding: 5rem 9rem 0;
            }

            .nav__inner {
                /*	padding: 0 rem(48); */
            }
        }

        &:not(.home) {
            .nav__list {
                flex: 1;
                flex-basis: 0;
            }
        }

        &__live-category {
            margin-left: 1rem;
            justify-content: flex-start;
            border-left: 1px solid white;
            padding: 0 2rem 0 1rem;
            width: auto;

            span + span {
                margin-left: 2rem;
            }

            &-name {
                white-space: nowrap;
            }
        }

        &--inverted {
            .nav__list {
                background: none;
            }
        }
    }

    .site-title,
    .site-title:not(.home) {
        width: auto;

        .site-title__logo {
            width: 130px;
            height: 20px;
        }

        .js-close-page {
            width: auto;
        }
    }

    .site-title.home {
        margin-top: 0;
        width: auto;
        max-width: 300px;
        height: rem(36);
        margin-right: rem(24);
        flex: 1;
        flex-basis: 0;

        .site-title__logo {
            width: 100%;
            max-width: 300px;
            max-width: none;
            margin-top: 0;
        }
    }

    .nav-bar.open {
        height: 32rem;
    }

    .menu {
        &__inner {
            flex-direction: row;
            padding: rem(72) 0 rem(108);
        }

        &-trigger__inner {
            margin-right: -3rem;
        }

        &__column {
            width: calc((100% - 4rem) * 0.3333);

            li {
                &:nth-child(5) {
                    transition-delay: 0s;
                }
                &:nth-child(4) {
                    transition-delay: 0s;
                }
                &:nth-child(3) {
                    transition-delay: 0.05s;
                }
                &:nth-child(2) {
                    transition-delay: 0.1s;
                }
                &:nth-child(1) {
                    transition-delay: 0.15s;
                }

                .open & {
                    &:nth-child(1) {
                        transition-delay: 0.2s !important;
                    }
                    &:nth-child(2) {
                        transition-delay: 0.25s !important;
                    }
                    &:nth-child(3) {
                        transition-delay: 0.3s !important;
                    }
                    &:nth-child(4) {
                        transition-delay: 0.35s !important;
                    }
                    &:nth-child(5) {
                        transition-delay: 0.4s !important;
                    }
                }
            }
        }
    }

    .search {
        &__input {
            background-position: right bottom 19px;
            background-size: 28px;
            padding: 1.5rem 40px 1.5rem 0;
            height: 6rem;
        }

        &__results {
            margin: rem(36) auto rem(48);
        }

        &__pagination {
            margin: rem(48) auto;
        }

        &__arrow {
            .big-carousel & {
                &--left {
                    left: unset;
                    right: 6rem;
                }

                &--right {
                    right: 4rem;
                }
            }

            .carousel__container--home & {
                bottom: 6rem;

                &--left {
                    left: 9rem;
                }

                &--right {
                    right: 9rem;
                }
            }
        }
    }

    .footer {
        padding: rem(60) 0 rem(96);

        &__column {
            &--right {
                text-align: right;
                padding-right: 0;
            }
        }
    }

    .form {
        margin: 6rem 0;

        &.newsletter-form {
            margin-bottom: 3rem;
        }

        &__alert {
            position: absolute;
            // padding: 2rem;
            // left: 2rem;

            p {
                width: 100%;
            }
        }
    }

    .mailchimp-form {
        padding: rem(24) rem(60);
    }

    .page-title {
        padding-top: rem(60);

        &.inverted {
            border-top-color: white;
        }

        &__spacing {
            margin-bottom: rem(60);
        }
    }

    .section-title {
        margin: rem(84) auto rem(72);

        &.has-categories {
            margin: rem(24) auto rem(72);
        }

        .module__background {
            &.bg--full {
                top: -7rem;
                height: calc(100% + 13rem);

                &.bg--long {
                    height: calc(100% + 30rem);
                }
            }

            &.bg--half {
                top: 0;
                height: calc(100% + 6rem);

                &.bg--long {
                    height: calc(100% + 23rem);
                }
            }

            &-buffer {
                padding: 6rem 0;

                &.top-only {
                    padding-bottom: 0;
                }
            }
        }
    }

    .text-area {
        margin: rem(72) auto;

        p,
        h3,
        h4:not(.form__title),
        pre,
        figure:not(.image-section) {
            margin-left: rem(108);
        }

        p,
        pre,
        figure:not(.image-section) {
            width: calc(((100% - 13rem) * 0.6666) + 2rem);
        }

        ul,
        ol {
            margin-left: rem(108);
        }

        blockquote {
            float: right;
            width: calc((100% - 13rem) * 0.3333);
            padding: rem(96) rem(24);
            margin-left: rem(24);
        }

        .image-section,
        form {
            margin: rem(72) 0;
            width: calc((100% * 0.6666) + 2rem);

            &:first-child {
                margin-top: 0;
            }

            p {
                margin-left: 0;
                padding-left: 0;
                width: 100%;
            }

            ul,
            ol,
            li {
                padding-left: 0;
                margin: 1rem 0 0;
            }
        }

        .audio-player-embed {
            margin-left: 0;

            iframe {
                margin-left: rem(108);
            }
        }

        &__title {
            margin-left: rem(108);
            padding-bottom: rem(60);
        }
    }

    .audio-player-embed {
        margin-left: rem(108);

        iframe {
            margin-bottom: rem(64);
            max-width: calc(66.66% + 2rem);
        }
    }

    .article {
        &__categories {
            margin: 0 0 rem(48);
        }

        &__category {
            position: relative;
            border-bottom: 1px solid $dark-blue;
            color: $dark-blue;
            .invert-page & {
                color: white;
                border-bottom-color: white;
            }
        }

        &__share {
            position: absolute;
            top: 1.8rem;
            left: -9rem;
            width: rem(48);
            flex-direction: column;
            text-align: center;
            white-space: nowrap;
            margin-top: 0;

            span {
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }

    .homepage__slide {
        min-height: 650px;

        &-overlay {
            .home & {
                padding: rem(48);
            }
        }

        &-content {
            bottom: auto;
            top: 50%;
            transform: translateY(-50%);
            max-width: 840px;
            padding: 0 rem(108);
        }
    }

    .slide {
        padding: 12rem 0 10rem;

        &__background.homepage {
            min-height: auto;
        }
    }

    .post-feed {
        margin: rem(96) auto;

        &__inner {
            margin-top: -2rem;
        }

        &__flex {
            flex-direction: row;
            flex-flow: row wrap;
            width: 100%;

            &.row--reverse {
                flex-flow: row-reverse;
            }
        }
    }

    .post {
        & + & {
            margin-left: 2rem;
        }
    }

    .row--reverse {
        .post + .post {
            margin-left: 0;
            margin-right: 2rem;
        }
    }

    .article-link,
    .article-carousel {
        margin-top: 2rem;
        width: calc((100% - 4rem) * 0.3333);
        padding: rem(48) rem(48) rem(72);

        &--wide {
            width: calc(((100% - 4rem) * 0.6666) + 2rem);
            padding: rem(60);

            &.in-fullwidth {
                padding: 0 rem(60);
            }
        }

        &--fullwidth {
            width: 100%;
            padding: 0;
        }

        &__wide-block {
            padding: rem(84) rem(48);
        }
    }

    .rollover {
        padding: 2rem;
    }

    .quick-link {
        width: calc((100% - 4rem) * 0.3333);
        margin-right: 2rem;
        margin-top: 2rem;
        padding: 2rem;

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        .video-icon {
            top: 2rem;
            left: 2rem;
        }
    }

    .carousel-nav {
        &__position {
            padding-bottom: rem(48);

            .home & {
                bottom: 4rem;
                transition: bottom 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }

        &__outer {
            .carousel__container--home & {
                bottom: rem(80);
                padding: 0 rem(108);
            }
        }
    }

    .find-out-more {
        &__position {
            width: calc(100% - 120px);
            height: calc(100% - 360px);
            top: 55%;
        }

        &__container {
            width: auto;

            svg {
                width: auto;
                height: 100%;
            }
        }
    }

    .big-slide {
        &__layout {
            flex-direction: row;
            flex-flow: row;
            align-items: center;
        }

        &__pseudo-images {
            display: block;
            width: 50%;
        }

        &__pseudo-image {
            width: 25vw;
        }

        &__text {
            flex: 1;
            flex-basis: 0;
            min-width: 350px;
            padding-bottom: 6rem;
        }

        &__images {
            width: 50vw;
            position: absolute;
            right: 100%;
            flex-flow: row;
            top: 50%;
            transform: translate(100%, -50%);
            // min-width: 620px;
            margin-bottom: 0;
            right: 0;

            .current & {
                transform: translateY(-50%);
            }
        }

        &__image {
            width: 44%;
            min-width: unset;
            transform: translateX(-10%);

            &.first {
                min-width: unset;
                width: 50%;
                transform: none;
                // transition: transform 0.6s ease-in-out;
            }
        }
    }

    .big-carousel {
        .carousel-nav__position {
            transform: none;
            left: 0;
            width: 100%;
            padding: 0 0 3rem;

            .standard-page-gutter {
                position: relative;
            }

            .carousel-nav__container {
                justify-content: flex-start;
                margin: 0 auto;
                max-width: 1348px;
                // padding: 0 4rem;
            }

            .carousel-nav__item:first-of-type {
                margin-left: 0;
            }
        }
    }

    .featured-video {
        &__title {
            padding: rem(60);

            &-inner {
                flex-direction: row;
                justify-content: flex-start;
                height: auto;
                align-items: center;

                span + span {
                    margin-left: 2rem;
                }
            }
        }

        &__info {
            width: calc((100% - 4rem) * 0.3333);
            padding-right: 2rem;
        }

        &__container {
            width: calc(((100% - 4rem) * 0.6666) + 2rem);

            &.fullwidth {
                width: 100%;

                .featured-video__title-inner span + span {
                    margin-left: 1.5rem;
                }
            }
        }

        &__inner {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &.reverse {
                flex-flow: row-reverse;

                .featured-video__info {
                    padding-left: 2rem;
                    padding-right: 0;
                }
            }
        }
    }

    .sitemap {
        ul.ul-top,
        ul.ul-sub,
        h3 {
            margin-left: 9rem;
        }
    }

    .cookies-notice {
        &__outer {
            flex-direction: row;
            align-items: center;
        }
    }

    .hide-desktop {
        display: none;
    }

    .tablet-only {
        display: none;
    }
}
