/*-----------------------------------*\
	$CONTENTS
\*-----------------------------------*/

//	* CONTENTS.........................You're reading it!
//	* IMPORTS..........................Start with a level playing field / general includes
//	* BOX SIZING.......................Set border-box globally
//	* CLEARFIX.........................Clearfix set up
// 	* IMAGE REPLACE....................Remove screen reader text from view
//	* VARIABLES........................Variables
//	* MIXINS / FUNCTIONS...............Mixins & Functions
// 	* FONTS............................Fonts
//	* ICONS............................Icons
//	* BASE STYLES......................Styles
//	* SPECIFIC STYLES..................Styles

/*-----------------------------------*\
	$IMPORTS
\*-----------------------------------*/

@import 'reset';

/*-----------------------------------*\
	$BOX SIZING
\*-----------------------------------*/

*,
*:before,
*:after {
    box-sizing: border-box;
}

/*-----------------------------------*\
	$CLEARFIX
\*-----------------------------------*/

// For modern browsers

// 1. The space content is one way to avoid an Opera bug when the
//		contenteditable attribute is included anywhere else in the document.
//		Otherwise it causes space to appear at the top and bottom of elements
//		that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//		`:before` to contain the top-margins of child elements.

.cf:before,
.cf:after {
    content: ' '; // 1
    display: table; // 2
}

.cf:after {
    clear: both;
}

/*-----------------------------------*\
	$IMAGE REPLACE
\*-----------------------------------*/

.ir {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

/*-----------------------------------*\
	$VARIABLES
\*-----------------------------------*/

// Font sizing
$font-size-root: 12;
$font-size-body: 18;
$line-height-body: 24;

$pg-size-small: 14;
$pg-size-large: 22;
$blockquote-size: 18;
$med-title-size: 30;
$large-title-size: 44;
$med-line-height: 36;
$double-line-height: 48;

// Font weights
$postgrotesk-book: 500;
$postgrotesk-bold: 700;

// Colours
$dark-blue: #132436;
$orange: #ee9755;
$coral: #e6697b;
$peach: #f6e2c3;
$sea-green: #066378;
$light-blue: #7eb3bf;
$brown: #bf7d65;
$yellow: #f6d573;
$off-white: #f8f7f7;
$link-red: #ff5f58;
$base-font-colour: $dark-blue;

// Sprite Sizing
$sprite-w: 1000px;
$sprite-h: 1000px;

// Spacing + Sizing
$page-gutter: 18;
$base-spacing: 24;

// Break Points
$b-p-desktop: 920px;
$b-p-widescreen: 1200px;

// Transitions
$global-opacity-transition: all 0.15s ease-in-out;
$global-opacity-knock-back: 0.8;
$layer-transition-t: 450ms;
$layer-clipboard-transition-t: 450ms;

/*-----------------------------------*\
	$MIXINS / FUNCTIONS
\*-----------------------------------*/

// Rem calculator
@function rem($target) {
    @return ($target / $font-size-root) * 1rem;
}

// Em calculator
@function em($target, $context: $font-size-root) {
    @return ($target / $context) * 1em;
}

@mixin icon-size($new-width, $orig-width, $orig-height, $orig-x, $orig-y) {
    // Get scale factor
    $scale: $new-width / $orig-width;

    // Set icon dimensions
    width: round($new-width) * 1px;
    height: round($orig-height * $scale) * 1px;

    // Set new sprite size
    background-size: round($sprite-w * $scale) round($sprite-h * $scale);

    // Set new background position co-ords
    background-position: round($orig-x * $scale * 1px) round($orig-y * $scale * 1px);
}

@mixin use-body-font {
    font-family: 'PostGrotesk', post-grotesk, sans-serif;
    font-size: rem($font-size-body);
    line-height: rem($line-height-body);
}

@mixin use-big-caslon {
    font-family: 'Big Caslon FB', big-caslon-fb, serif;
    font-weight: 700;
    font-style: normal;
}

@mixin reset-small-font {
    font-size: rem($pg-size-small);
}
@mixin reset-large-font {
    font-size: rem($pg-size-large);
}

@mixin input-padding {
    padding: 1rem 1rem 1.1rem;
}

@mixin autofill-override($colour, $textcolour) {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $colour inset;
        -webkit-text-fill-color: $textcolour;
    }
}

@mixin placeholders($colour) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $colour;
        opacity: 1;
        transition: $global-opacity-transition;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $colour;
        opacity: 1;
        transition: $global-opacity-transition;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $colour;
        opacity: 1;
        transition: $global-opacity-transition;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: $colour;
        opacity: 1;
        transition: $global-opacity-transition;
    }
    &::placeholder {
        color: $colour;
        opacity: 1;
        transition: $global-opacity-transition;
    }
}

@keyframes dotanim {
    0% {
        transform: scale(0.6);
        opacity: 0.6;
    }
    20% {
        transform: scale(0.6);
        opacity: 0.6;
        background-color: $peach;
    }
    50% {
        transform: scale(0.7);
        opacity: 1;
        background-color: $coral;
    }
    80% {
        transform: scale(0.6);
        opacity: 0.6;
    }
    100% {
        transform: scale(0.6);
        opacity: 0.6;
        background-color: $peach;
    }
}

/*-----------------------------------*\
	$FONTS
\*-----------------------------------*/

@font-face {
    font-family: 'PostGrotesk';
    src: url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Book.woff2') format('woff2'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Book.woff') format('woff'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Book.eot') format('opentype');
    font-weight: $postgrotesk-book;
    font-style: normal;
}

@font-face {
    font-family: 'PostGrotesk';
    src: url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Bold.woff2') format('woff2'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Bold.woff') format('woff'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-Bold.eot') format('opentype');
    font-weight: $postgrotesk-bold;
    font-style: normal;
}

@font-face {
    font-family: 'PostGrotesk';
    src: url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BookItalic.woff2') format('woff2'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BookItalic.woff') format('woff'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BookItalic.eot') format('opentype');
    font-weight: $postgrotesk-book;
    font-style: italic;
}

@font-face {
    font-family: 'PostGrotesk';
    src: url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BoldItalic.woff2') format('woff2'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BoldItalic.woff') format('woff'),
        url('/assets/webkit/fonts/postgrotesk/PostGrotesk-BoldItalic.eot') format('opentype');
    font-weight: $postgrotesk-bold;
    font-style: italic;
}

/*-----------------------------------*\
	$ICONS
\*-----------------------------------*/

.sprite {
    display: inline-block;
    vertical-align: middle;
    background: url('/assets/webkit/sprite.svg?v=1.1');
    background-size: $sprite-w $sprite-h;
    width: 32px;
    height: 32px;
}

.icon {
    &-logo {
        position: relative;
        background-position: -150px -50px;
        width: 130px;
        height: 24px;
        &--invert {
            background-position: 0px -50px;
        }
        &--small {
            background-position: -650px -50px;
            width: 90px;
            height: 17px;
        }
    }

    &-logo-big {
        background-position: -300px -50px;
        width: 300px;
        height: 63px;
    }

    &-plus {
        position: relative;
        width: 20px;
        height: 1px;
        transition: transform 150ms, background-color 0.3s ease-in-out, opacity 250ms ease-in-out;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: inherit;
            height: inherit;
            background: inherit;
            transform: rotate(90deg);
        }

        &--cross {
            transform: rotate(135deg);
        }

        &--white {
            background: white;
        }

        &--small {
            width: 12px;
            height: 1px;
        }
    }

    &-hamburger {
        position: relative;
        display: inline-block;
        width: 2rem;
        height: 2rem;

        .hamburger-inner {
            background: white;
            top: 50%;
            display: block;
            position: absolute;
            width: inherit;
            height: 1px;
            margin-top: -1px;
            transition: transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19),
                background-color 0.25s ease 0.28s;

            &:before {
                top: -9px;
                transition: top 75ms ease 0.12s, opacity 75ms ease;
            }

            &:after {
                bottom: -9px;
                transition: bottom 75ms ease 0.12s,
                    transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &:after,
            &:before {
                display: block;
                content: '';
                position: absolute;
                width: inherit;
                height: inherit;
                background-color: inherit;
            }

            .invert-page .nav-bar & {
                background: $dark-blue;
            }
        }

        &.closed {
            .hamburger-inner {
                transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: rotate(45deg);

                &:after {
                    transform: rotate(-90deg);
                    bottom: 0;
                    transition: bottom 75ms ease,
                        transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
                }

                &:before {
                    top: 0;
                    transition: top 75ms ease, opacity 75ms ease 0.12s;
                    opacity: 0;
                }
            }
        }
    }

    &-facebook {
        width: 8px;
        height: 14px;
        background-position: 0px -150px;

        .invert-page &,
        footer & {
            background-position: 0px -200px;
        }
    }

    &-twitter {
        width: 18px;
        height: 14px;
        background-position: -50px -150px;

        .invert-page &,
        footer & {
            background-position: -50px -200px;
        }
    }

    &-instagram {
        width: 14px;
        height: 14px;
        background-position: -100px -150px;

        .invert-page &,
        footer & {
            background-position: -100px -200px;
        }
    }

    &-pinterest {
        width: 14px;
        height: 14px;
        background-position: -150px -150px;

        .invert-page &,
        footer & {
            background-position: -150px -200px;
        }
    }

    &-youtube {
        width: 20px;
        height: 14px;
        background-position: -200px -150px;

        .invert-page &,
        footer & {
            background-position: -200px -200px;
        }
    }

    &-share-facebook {
        background-position: 0 0;
        width: 30px;
        height: 30px;

        .invert-page & {
            background-position: -100px 0;
        }
    }

    &-share-twitter {
        background-position: -50px 0;
        width: 30px;
        height: 30px;

        .invert-page & {
            background-position: -150px 0;
        }
    }

    &-arrow {
        background-position: -50px -100px;
        width: 8px;
        height: 13px;

        &--inverted {
            background-position: -100px -100px;
            width: 8px;
            height: 13px;
        }

        &--right {
            transform: rotate(180deg);
        }

        &--down {
            transform: rotate(270deg);
        }
    }

    &-video {
        width: 30px;
        height: 21px;
        background-position: 0px -100px;
    }

    &--search {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0 rem(4) 0 0;
        background-image: url('/assets/webkit/icons/search.png');
        background-size: cover;
        vertical-align: -2px;

        .nav--inverted & {
            background-image: url('/assets/webkit/icons/search-white.png');
        }
    }
}

/*-----------------------------------*\
	$BASE STYLES
\*-----------------------------------*/

html {
    position: relative;
    font-size: $font-size-root * 1px;
}

body {
    font-weight: $postgrotesk-book;
    background-color: $off-white;
    @include use-body-font;
    color: $base-font-colour;
    -webkit-font-smoothing: subpixel-antialiased;
    overscroll-behavior-y: contain;

    &.invert-page {
        background: $dark-blue;
        color: white;
    }
}

blockquote {
    font-size: rem($blockquote-size);
    @include use-big-caslon;
}

.small-font,
.tiny-font {
    @include reset-small-font;
    line-height: rem($line-height-body);
}

.body-font {
    @include use-body-font;
}

.large-font {
    @include reset-large-font;
}

.med-title {
    font-size: rem($med-title-size);
    line-height: rem($med-line-height);
    @include use-big-caslon;
}

.large-title {
    font-size: rem($large-title-size);
    line-height: rem($double-line-height);
    @include use-big-caslon;
}

p {
    margin: 0 auto rem($base-spacing);
}

strong,
b {
    font-weight: $postgrotesk-bold;
}

a {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: inherit;

    &:link,
    &:visited {
        color: inherit;
        text-decoration: none;
    }

    &:focus,
    &:active {
        border: none;
    }
}

input[type='text'],
input[type='email'],
input[type='number'],
textarea,
select {
    display: block;
    font: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    color: inherit;
    appearance: none;
    background: #f4efef;
    width: 100%;
    max-width: 100%;
    @include input-padding;
    border: none;
    border-radius: 0;
    outline: none;
    @include autofill-override(#f4efef, $dark-blue);

    .form-invert & {
        background: #234161;
        @include autofill-override(#234161, #ffffff);
        @include placeholders($off-white);
    }
}

textarea {
    resize: none;
}

select {
    background: $off-white url('/assets/webkit/icons/arrow_down.png') no-repeat;
    background-size: 12px;
    background-position: right 18px center;
    padding: 1rem 4rem 1.1rem 1rem;

    &.is-invalid {
        color: $link-red;
    }

    &.required {
        option:first-of-type {
            &:after {
                content: ' *';
                display: inline-block;
            }
        }
    }
}

label {
    display: flex;
    // margin-bottom: 1rem;
    align-items: center;

    &.bold {
        font-weight: 700;
        margin-bottom: 2rem;
    }

    &.with-instructions {
        margin-bottom: 1rem;
    }

    &.required {
        &:after {
            content: '*';
            color: $link-red;
            display: inline-block;
            margin-left: 0.5rem;
        }
    }
}

input[type='radio'] {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    background: $peach;
    transition: $global-opacity-transition;
    cursor: pointer;
    margin-right: 1rem;
    margin-top: 0.5rem;
    float: left;
    &:checked {
        background: $dark-blue;
    }
}

pre {
    background-color: $off-white;
    white-space: pre-wrap;
    display: block;
    padding: 24px;
    margin: 2rem 0;
    @include reset-small-font;
}

.standard-page-gutter {
    width: 100%;

    &:not(.full-mobile) {
        padding: 0 rem($page-gutter);
        margin-left: auto;
        margin-right: auto;
    }
}

.js-pageloader {
    transition: opacity 0.15s ease-in-out;

    &.loading {
        opacity: 0;
    }
}

.easing-contents {
    transition: transform 0.6s ease-in-out, opacity 1s ease-in-out,
        -webkit-transform 0.6s ease-in-out;
    transform: translate3d(0, 4rem, 0);
    opacity: 0;

    &.ease-in {
        opacity: 1;
        transform: none;
    }
}

.page-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity $layer-transition-t ease-in-out, height 0ms $layer-transition-t;
    z-index: 10;

    &:first-child,
    &.reveal {
        height: 100%;
        transition: opacity $layer-transition-t ease-in-out,
            background-color $layer-transition-t ease-in-out $layer-transition-t;
        background: white;
    }

    .invert-page & {
        &:first-child,
        &.reveal {
            background: $dark-blue;
        }
    }

    &:first-child,
    &.add-to-flow {
        position: relative;
        height: auto;
        overflow: visible;
    }

    //&:first-child,
    &.add-to-flow {
        opacity: 1;
    }

    &.disabled {
        opacity: 0.2;
        z-index: -1;
    }

    &.hide {
        display: none;
    }

    &__clipboard {
        opacity: 0;

        .page-content:first-child.reveal &,
        &.reveal {
            opacity: 1;
            transition: opacity $layer-clipboard-transition-t ease-in-out;
        }
    }
}

.image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $dark-blue;
    opacity: 0.1;
}

.img-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.fullwidth-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.loaded {
        opacity: 1;
    }
}

.form {
    width: 100%;
    margin: 4rem 0;
    transition: $global-opacity-knock-back;
    position: relative;

    &-invert {
        color: white;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 2rem;
        @include use-big-caslon;
        font-size: rem($med-title-size);
        line-height: rem($med-line-height);
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin: rem($base-spacing) -1rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__column {
        flex: 1 0;
        // padding: 1rem 0;
        margin: 0 1rem;
    }

    &__text {
        margin-bottom: 2rem;

        &-muted {
            @include reset-small-font;
            font-style: italic;
        }
    }

    &__response {
        font-style: italic;
        margin-top: 2rem;
        opacity: 0;
        transition: $global-opacity-transition;

        &.show {
            opacity: 1;
        }
    }

    &__submit {
        font: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        color: inherit;
        font-size: inherit;
        border-radius: 500px;
        display: block;
        padding: 0.5rem 1.5rem;
        background-color: $peach;
        transition: $global-opacity-transition;
        border: none;
        appearance: none;
        outline: none;
        cursor: pointer;

        &:hover {
            background-color: $dark-blue;
            color: white;
        }

        &:disabled,
        &[disabled] {
            opacity: 0.3;
            background-color: $peach;
            color: $dark-blue;
        }

        .form-invert & {
            background-color: $coral;

            &:hover {
                background-color: white;
                color: $coral;
            }

            &:disabled,
            &[disabled] {
                background-color: $coral;
                color: white;
            }
        }
    }

    &-check {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        flex-flow: row wrap;

        &-label {
            cursor: pointer;
        }

        &-inline {
            display: inline-block;
            margin-right: 2rem;
        }

        &.checkbox {
            input[type='checkbox'] {
                opacity: 0;
                width: 0;
                margin: 0;

                &.form__is-invalid + label {
                    color: $link-red;
                }
            }

            ul.form__help-block.form__errors {
                min-width: 100%;
            }

            label {
                position: relative;
                margin: 0 1rem 0 3rem;
                cursor: pointer;

                &:before {
                    top: 3px;
                    left: -3rem;
                    position: absolute;
                    content: '';
                    display: inline-block;
                    height: 16px;
                    width: 16px;
                    background: $peach;
                }

                &:after {
                    position: absolute;
                    left: -3.2rem;
                    top: 7px;
                    content: none;
                    display: inline-block;
                    height: 6px;
                    width: 9px;
                    border-left: 2px solid $dark-blue;
                    border-bottom: 2px solid $dark-blue;
                    transform: rotate(-45deg);
                    margin-left: 0.45rem;
                }
            }

            input[type='checkbox'] {
                &:checked + label::after {
                    content: '';
                }

                &:focus + label::before {
                    // outline: rgb(59, 153, 252) auto 5px;
                }
            }
        }
    }

    &__errors {
        list-style-type: none;
        padding: 0;
        margin: 0;
        color: $link-red;

        li {
            padding: 0;
            margin: 2rem 0 0;
        }

        &.hidden {
            display: none;
        }
    }

    &__invalid-feedback {
        display: none !important;
    }

    &__errors,
    &__alert {
        @include reset-small-font;
        font-style: italic;
    }

    &__is-invalid {
        @include placeholders($link-red);
    }

    &__alert {
        position: fixed;
        top: 8rem;
        left: 50%;
        width: calc(100% - 11rem);
        background: $dark-blue;
        max-width: 500px;
        padding: 2rem 5rem 2rem 1.5rem;
        color: white;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
        transform: translateX(-50%);

        &.reveal {
            opacity: 1;
            visibility: visible;
        }

        p {
            margin-bottom: 0;
        }

        .icon-hamburger {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            cursor: pointer;
            transform: translateY(-50%);
            transition: $global-opacity-transition;
            &:hover {
                opacity: $global-opacity-knock-back;
            }
        }
    }

    &.loading {
        opacity: 0.3;
    }
}

/*-----------------------------------*\
	SPECIFIC STYLES
\*-----------------------------------*/

.nav-bar {
    background: $dark-blue;
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    height: rem(36);
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.9, 0.02, 0.6, 1);

    &.open {
        max-height: none;
        height: 100vh;
    }

    .invert-page & {
        background: white;
        color: $dark-blue;
    }
}

.pseudo-nav-bar {
    height: rem(36);
    width: 100%;
}

.pseudo-header {
    height: 7rem;
}

.menu-trigger {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;

    &__inner {
        display: flex;
        align-items: center;
        transition: $global-opacity-transition;
        cursor: pointer;

        &:hover {
            opacity: $global-opacity-knock-back;
        }

        .icon {
            margin-left: 1rem;
        }
    }
}

.nav {
    margin: 0 auto rem(24);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 998;

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: column-reverse;
    }

    &__list {
        display: flex;
        padding: rem(24) rem(18);
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;

        li {
            margin: 0 1rem;
            cursor: pointer;
            white-space: nowrap;
            opacity: all 0.15s ease-in-out;
            transition: $global-opacity-transition;

            &.featured {
                padding: 0.5rem 1.5rem;
                border-radius: 500px;
                background: $peach;

                &:hover {
                    color: white;
                    background: $dark-blue;
                }
            }

            &:not(.featured) {
                a.active {
                    border-bottom: 1px solid;
                }

                &:hover {
                    opacity: $global-opacity-knock-back;
                }
            }
        }
    }

    &__live-category {
        cursor: pointer;
        padding: 1rem 1.5rem;
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &.active {
            display: flex;
        }
    }

    &--inverted {
        color: white;

        .nav__list {
            background: $coral;
        }

        .nav__list li.featured {
            background: white;
            color: #db2944;

            &:hover {
                background: $coral;
                color: white;
            }
        }
    }
}

.site-title {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    transition: all 0.25s ease-in-out;

    &:not(.home) {
        .js-close-page {
            width: 100%;
        }

        .site-title__logo {
            position: relative;
            width: 0;
        }
    }

    span {
        transition: $global-opacity-transition;
    }

    span:not(.icon-logo-big):hover {
        opacity: $global-opacity-knock-back;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: opacity 0.25s ease-in-out 0.25s, fill $layer-transition-t ease-in-out;
    }

    .christianity-logo {
        width: 100%;
    }

    .christianity-logo-big {
        opacity: 0;
        visibility: hidden;
        transition-delay: 0s;
    }

    &.home {
        width: 350px;
        max-width: calc(100% - 60px);
        margin-top: 3rem;

        .christianity-logo {
            opacity: 0;
            visibility: hidden;
            transition-delay: 0s;
        }

        .christianity-logo-big {
            opacity: 1;
            visibility: visible;
            transition-delay: 0.25s;
        }
    }

    a {
        transition: $global-opacity-transition;

        &:hover {
            opacity: $global-opacity-knock-back;
        }
    }
}

.mobile-logo {
    width: 90px;
    display: flex;
    align-items: center;

    &__inner {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }

        a {
            display: flex;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.menu {
    &__inner {
        display: flex;
        flex-direction: column;
        color: inherit;
        padding: rem(24) 0;
    }

    &__column {
        width: 100%;

        li {
            opacity: 0;
            transition: opacity 0.15s ease-in-out;

            .open & {
                opacity: 1;
                transition-delay: 0.2s;
            }

            &.featured {
                color: $coral;
                .invert-page & {
                    // color: $peach;
                }
            }

            a + a {
                margin-left: 1rem;
            }
        }

        li + li {
            margin-top: rem(24);
        }

        a {
            border-bottom: none;
            transition: $global-opacity-transition;
            &:hover {
                opacity: $global-opacity-knock-back;
            }
        }
    }
}

.search {
    &__overlay {
        width: 100vw;
        height: rem(36);
        position: fixed;
        top: 0;
        left: 0;
        background: $dark-blue;
        color: white;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.1s linear 0.3s, opacity 0.1s ease-in-out 0.3s,
            height 0.3s cubic-bezier(0.9, 0.02, 0.6, 1);

        &.show {
            visibility: visible;
            opacity: 1;
            height: 100vh;
            transition: visibility 0.1s linear, opacity 0.1s ease-in-out,
                height 0.3s cubic-bezier(0.9, 0.02, 0.6, 1);
        }
    }

    &__bar {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        height: rem(36);
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    &__position {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;

        form {
            transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
            transform: translateY(10px);
            opacity: 0;

            .show & {
                transform: none;
                opacity: 1;
                transition-delay: 0.3s;
            }
        }
    }

    &-trigger {
        margin: 0.5rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__input {
        appearance: none;
        -webkit-appearance: none;
        border-radius: 0;
        background-image: url('../../assets/webkit/icons/search-white.png');
        background-position: right center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 20px;
        padding: 1.5rem 30px 1.5rem 0;
        height: 5rem;
        color: white;
        font-weight: 400;
        border: none;
        border-bottom: 1px solid white;
        width: 100%;
        max-width: 400px;
        @include placeholders(white);
    }

    &__results {
        margin: rem(24) auto;
    }

    &__results-title {
        width: 100%;
        text-align: center;
        padding: 1.5rem;
        border-bottom: 1px solid $dark-blue;
    }

    &__pagination {
        margin: rem(24) auto;

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .current-page {
            color: $coral;
            font-weight: 700;
        }
    }

    &__arrow {
        opacity: 0.2;
        transition: opacity 0.25s ease-in-out;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -12px;

        &.active {
            opacity: 0.9;

            &:focus,
            &:hover {
                opacity: 0.5;
            }
        }

        .big-carousel & {
            position: absolute;
            z-index: 30;
            top: 50%;
            transform: translateY(-50%);

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        .carousel__container--home & {
            &--down {
                position: absolute;
                z-index: 40;
                bottom: 2rem;
                margin: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.mailchimp-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3rem);
    max-width: 600px;
    background: $dark-blue;
    z-index: 998;
    padding: rem(24) rem(36);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.22), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%) translateY(12px);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }

    .icon-hamburger {
        position: absolute;
        top: 2rem;
        right: 2rem;
        transition: $global-opacity-transition;
        cursor: pointer;
        &:hover {
            opacity: $global-opacity-knock-back;
        }
    }

    .form {
        transition: $global-opacity-transition;
    }

    .loading-icon {
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in-out;
    }

    &.loading {
        .form {
            opacity: 0.3;
        }

        .loading-icon {
            opacity: 1;
            visibility: visible;
        }
    }
}

$dotSize: 3rem;
$animDelay: 0.2s;

.loading-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    transition: visibility 0.1s ease-in-out, opacity 0.1s ease-in-out;
    visibility: hidden;
    opacity: 1;

    &.loading {
        visibility: visible;
        opacity: 1;
    }
}

.threedotloader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-icon & {
        height: 100%;
    }

    .dot {
        width: $dotSize;
        height: $dotSize;
        border-radius: $dotSize;
        background: $peach;
        margin: 0.2em;
        transform: scale(0);
        display: inline-block;
        animation: dotanim 1.5s infinite ease-in-out;

        @for $i from 0 to 4 {
            &:nth-child(#{$i + 1}) {
                animation-delay: $animDelay * $i;
            }
        }
    }
}

.footer {
    background: $off-white;
    color: $dark-blue;
    padding: rem(48) 0 rem(96);

    &__columns {
        display: flex;
    }

    &__column {
        width: 50%;
        padding-right: 4rem;

        a {
            transition: $global-opacity-transition;

            &:hover {
                color: $coral;
            }

            &:not(.social) {
                border-bottom-color: $dark-blue;
                &:hover {
                    border-bottom-color: $coral;
                }
            }
        }

        .social + .social {
            margin-left: 1rem;
        }
    }

    &__list {
        margin: 0 0 rem(24);
    }

    // &__img {
    //     max-width: 130px;
    // }

    &__copyright {
        margin-bottom: rem($base-spacing);
    }

    .christianity-logo {
        width: 100%;
        max-width: 130px;
        height: auto;
        margin-top: rem(12);
    }
}

.para-bind {
    max-width: 552px;
}

.quick-description {
    &::before {
        content: '\2014';
        display: block;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &__link {
        cursor: pointer;
        display: inline-block;
        transition: $global-opacity-transition;

        &:after {
            display: block;
            content: '';
            width: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;
            transition: width 0.25s ease-in-out;
            opacity: $global-opacity-knock-back;
        }

        &:hover {
            opacity: $global-opacity-knock-back;

            &:after {
                width: 100%;
            }
        }
    }

    &--article {
        max-width: rem(816);
        position: relative;
    }
}

.link-underline {
    cursor: pointer;
    display: inline-block;
    transition: $global-opacity-transition;

    &:after {
        display: block;
        content: '';
        width: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: inherit;
        transition: width 0.25s ease-in-out;
        opacity: $global-opacity-knock-back;
    }

    &:hover {
        opacity: $global-opacity-knock-back;

        &:after {
            width: 100%;
        }
    }
}

.bg {
    &--dark-blue {
        background: $dark-blue;
    }

    &--off-white {
        background: $off-white;
    }

    &--white {
        background: white;
        color: $dark-blue;
    }

    &--coral {
        background: $coral;
    }

    &--sea-green {
        background: $sea-green;
    }

    &--orange {
        background: $orange;
    }

    &--peach {
        background: $peach;
    }

    &--light-blue {
        background: $light-blue;
    }

    &--brown {
        background: $brown;
    }

    &--yellow {
        background: $yellow;
    }

    &.transparent {
        mix-blend-mode: multiply;
        //IE 9 and down
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
    }

    &.copy-invert {
        color: white;
    }
}

_:-ms-lang(x),
.bg.transparent {
    opacity: 0.6;
}

.link {
    &-red {
        color: $link-red;
    }

    &-coral {
        color: $coral;
    }
}

.filler-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.page-title {
    padding-top: rem(36);
    border-top: 1px solid $dark-blue;

    &__spacing {
        margin-bottom: rem(36);
    }
}

.section-title {
    margin: rem(48) auto rem(24);
    position: relative;

    &.has-categories {
        margin: rem(24) auto;
    }

    .module__background {
        &.bg--full {
            top: -4rem;
            height: calc(100% + 6rem);

            &.bg--long {
                height: calc(100% + 15rem);
            }
        }

        &.bg--half {
            top: 0;
            height: calc(100% + 2rem);

            &.bg--long {
                height: calc(100% + 11rem);
            }
        }

        &-buffer {
            padding: 2rem 0;

            &.top-only {
                padding-bottom: 0;
            }
        }
    }

    .dark-blue,
    .sea-green {
        color: $peach;
    }
}

.text-area,
.quick-description.not-found {
    a {
        color: inherit;
        box-shadow: inset 0 -6px 0 $peach;
        padding-bottom: 0;
        transition: box-shadow 0.4s ease;

        &:hover {
            box-shadow: inset 0 -1px $peach;
        }
    }

    .invert-page & {
        a {
            box-shadow: inset 0 -6px 0 $coral;

            &:hover {
                box-shadow: inset 0 -1px $coral;
            }
        }
    }
}

.text-area {
    margin: rem(48) auto;

    h3,
    h4 {
        font-size: rem($med-title-size);
        line-height: rem($med-line-height);
        @include use-big-caslon;
        margin-bottom: 2rem;
    }

    p,
    h3 {
        margin-left: 0;
        margin-right: 0;
        max-width: 552px;
    }

    .image-section,
    figure {
        margin: rem(24) 0;

        &:first-child {
            margin-top: 0;
        }

        a {
            &,
            &:hover {
                box-shadow: none;
            }
        }
    }

    figure:not(.image-section) {
        img {
            max-width: 100%;
            height: auto;
        }

        iframe {
            width: 100%;
        }
    }

    .image-section,
    p,
    pre {
        width: 100%;
        display: block;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            display: block;
            &.width-override {
                width: auto;
            }
        }
    }

    ul,
    ol {
        max-width: 552px;
        margin: rem(24) 0;
        padding-left: 1.8rem;

        li {
            padding-left: 1rem;
            margin-bottom: 1rem;
        }
    }

    ul:not(.form__errors) {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    blockquote {
        width: 100%;
        text-align: center;
        background: $off-white;
        padding: rem(60);
        margin-bottom: rem(24);
    }

    &__title {
        border-bottom: 1px solid $dark-blue;
        padding-bottom: rem(24);

        .invert-page & {
            border-bottom-color: white;
        }

        &.no-content {
            border-bottom-color: transparent;
        }
    }

    form {
        ul,
        ol,
        li {
            padding-left: 0;
            margin: 1rem 0 0;
        }
    }
}

.audio-player-embed {
    width: 100%;
    margin-bottom: rem(24);

    iframe {
        display: block;
        margin: 0;
    }

    div + iframe {
        margin-top: rem(24);
    }
}

.video-sizing {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.article {
    &__hero {
        position: relative;
        width: 100%;
    }
    &__categories {
        display: flex;
        flex-flow: row wrap;
        margin: 0 0 rem(24);

        li {
            margin-right: 2rem;
            transition: $global-opacity-transition;

            &:hover {
                opacity: $global-opacity-knock-back;
            }

            &.border {
                padding-right: 2rem;
                border-right: 1px solid $dark-blue;

                .invert-page & {
                    border-right-color: white;
                }
            }
        }
    }

    &__category {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: #fff;

        &-position {
            position: relative;
        }
    }

    &__share {
        display: flex;
        align-items: center;
        margin-top: rem(36);

        span {
            margin-right: 1rem;
        }

        &-link {
            transition: $global-opacity-transition;
            cursor: pointer;

            &:hover {
                opacity: $global-opacity-knock-back;
            }
        }
    }

    &__back-link {
        position: absolute;
        bottom: 0;
        height: rem(60);
        left: 0;
        width: 100%;
        color: white;
    }
}

.rollover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(230, 105, 123, 0.8);
    color: white;
    overflow: hidden;
    padding: 1.5rem;

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .hovers .rollover-trigger:hover &,
    &.reveal {
        min-height: 100%;
        opacity: 1;
        transition: opacity 225ms ease-in-out;
    }

    h3,
    p {
        margin-bottom: 1rem;
    }
}

.browsehappy {
    padding: 1rem;
    background: $dark-blue;
    color: white;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    p {
        margin: 0;
        color: #fff;
    }
}

.cookies-notice {
    position: fixed;
    left: 0;
    top: 100%;
    transform: translateY(-100%);
    width: 100%;
    background-color: #111111;
    color: white;
    z-index: 500;
    padding: 2rem 0;
    display: none;
    @include reset-small-font;

    &.show {
        display: block;
    }

    &__outer {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__title {
        font-weight: bold;
    }

    &__close {
        color: $dark-blue;
    }

    &__copy {
        padding-right: 4rem;

        p a {
            border-bottom: 1px solid white;
            transition: $global-opacity-transition;

            &:focus,
            &:hover {
                opacity: $global-opacity-knock-back;
            }
        }
    }
}

/*-----------------------------------*\
	MEDIA ITEMS
\*-----------------------------------*/

.height-define {
    &--sixteen-nine {
        padding-bottom: 56.25%;
    }

    &--eight-nine {
        padding-bottom: 112.5%;
    }

    &--square {
        padding-bottom: 100%;
    }

    &--panorama {
        padding-bottom: 43.75%;
    }
}

.tablet-only {
    display: none;
}

.mobile-only {
    display: block;
}

.module {
    &__bg-image,
    &__shadow,
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    &__shadow {
        background: linear-gradient(0deg, rgba(19, 36, 54, 0.7) 0%, rgba(19, 36, 54, 0) 100%);

        &--from-top {
            background: linear-gradient(180deg, rgba(19, 36, 54, 0.7) 0%, rgba(19, 36, 54, 0) 100%);
        }
    }

    &__bg-image {
        opacity: 0;
        transition: opacity 0.25s ease-out;

        &.loaded {
            opacity: 1;
        }
    }
}

.carousel {
    &__container {
        position: relative;
        width: 100%;
        overflow: hidden;
        display: flex;
        transition: height 0.3s cubic-bezier(0.9, 0.02, 0.6, 1);

        &.big-carousel {
            transition: none;
        }

        &.white,
        &.peach,
        &.off-white {
            .carousel-nav__item:after {
                background: $dark-blue;
            }
        }
    }
}

.carousel-nav {
    &__outer {
        .carousel__container--hero & {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .carousel__container--home & {
            position: absolute;
            left: 0;
            right: 0;
            bottom: rem(32);
            padding: 0 rem(16);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .big-carousel & {
            position: relative;
        }
    }

    &__position {
        position: absolute;
        bottom: 0;
        padding: rem(24) 0;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 30;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
    }

    &__item {
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            background: white;
            border-radius: 50%;
            transition: all 0.15s ease-in-out;
        }

        &.current {
            &:after {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}

.big-slide {
    position: absolute;
    min-width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 4rem 0;

    &:not(.current) {
        z-index: 0;
    }

    &.current {
        z-index: 10;
    }

    &__layout {
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
    }

    &__text {
        flex: 1;
        flex-basis: 0;
        padding-bottom: 2rem;
        opacity: 0;

        .current & {
            opacity: 1;
        }
    }

    &__pseudo-images {
        display: none;
    }

    &__images {
        width: calc(100% + 36px);
        margin: 0 -18px;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        margin-bottom: 2rem;
        // transition: opacity .25s ease-in-out;
        z-index: -1;
        transform: translateX(150%);

        .current & {
            opacity: 1;
            transform: none;
        }
    }

    &__image {
        position: relative;
        width: 50%;
        min-width: 50%;
        transform: translateX(10%);

        &.first {
            width: 55%;
            min-width: 55%;
            transform: none;
        }
    }
}

.slide {
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 12rem 0 10rem;
    color: white;
    width: 100%;

    &--ready {
        position: absolute;
        z-index: 10;
    }

    &--active {
        padding-bottom: 15rem;
    }

    &.current {
        z-index: 12;
    }

    &__img-wrap,
    &__img-mask,
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    &__text {
        opacity: 0;

        .current & {
            opacity: 1;
        }

        .js-carousel & {
            transform: translateX(-100%);
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__img-mask {
        background: linear-gradient(180deg, rgba(19, 36, 54, 0.8) 0%, rgba(19, 36, 54, 0.4) 100%);
    }

    &__background {
        opacity: 1;
        transition: opacity 0.1s ease-in-out;

        .js-carousel & {
            transform: translateX(-100%);
        }

        &.previous {
            z-index: 0;
            opacity: 0.8;
        }

        &.current {
            z-index: 1;
            opacity: 1;
            transition: none;
        }
    }
}

.js-carousel .slide {
    .js-link-container {
        transform: translateX(-200%);
    }
}

.homepage__slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 180px);
    min-height: 600px;
    max-height: 880px;
    overflow: hidden;

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        transition: padding 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
        display: flex;
        mix-blend-mode: multiply;
        opacity: 0;

        .fill-colour {
            height: 100%;
            width: 100%;
        }
    }

    &-content {
        position: absolute;
        left: 0;
        bottom: rem(100);
        width: 100%;
        padding: 0 1.5rem;
        color: white;
        opacity: 0;
        -webkit-font-smoothing: antialiased;

        h2 {
            @include use-big-caslon;
            font-size: rem($med-title-size);
            line-height: rem($med-line-height);
        }

        p {
            margin: 0 0 rem(16);
        }

        a {
            display: inline-block;
            margin: rem(12) 0 0;
            border-bottom: 1px solid white;
            transition: border 0.2s ease;

            &:hover {
                border-color: transparent;
            }
        }

        .tag {
            display: inline-block;
            margin: 0 0 rem(8);
            padding: rem(4) rem(8);
            border-radius: 4px;
            background: $dark-blue;
            font-size: rem(12);
            line-height: rem(14);
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }
        .current & {
            z-index: 1;
        }
    }
}

.slide__background.homepage {
    opacity: 1;
    z-index: -1;

    &.current,
    &.previous {
        z-index: -1;
    }
}

.find-out-more {
    &__position {
        position: absolute;
        width: 350px;
        height: 100%;
        //height: 450px;
        max-width: calc(100% - 60px);
        max-height: calc(100% - 180px);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 54%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        opacity: 0;

        .current & {
            opacity: 1;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        max-width: 100%;
        z-index: -1;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.post-feed {
    margin: rem(36) auto;

    &__inner {
        margin-top: -1.5rem;
    }

    &__flex {
        display: flex;
        flex-direction: column;

        &.row--reverse {
            flex-flow: column-reverse;
        }
    }
}

.row {
    .post {
        color: white;
    }

    .article-link__category {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
    }
}

.article-link,
.article-carousel {
    width: 100%;
    margin-top: 1.5rem;
    position: relative;
    overflow: hidden;

    h3 {
        transition: $global-opacity-transition;
    }

    &__category {
        height: rem(60);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: rem(18);

        a {
            transition: $global-opacity-transition;

            &:hover {
                opacity: $global-opacity-knock-back;
            }
        }
    }

    &__wide-block {
        padding: rem(36) rem(18);
        position: relative;

        &.invert-text {
            color: $dark-blue;
        }
    }

    &--narrow {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &--wide {
        h3:hover {
            opacity: $global-opacity-knock-back;
        }
    }

    &--fullwidth {
        padding-left: 0;
        padding-right: 0;

        .article-link__category {
            border-bottom: 1px solid $dark-blue;
        }
    }
}

.article-carousel__item {
    color: white;
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 1.5rem;
    z-index: 10;

    &-title {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100% - 120px);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        padding: 0 2rem;

        .current & {
            opacity: 1;
        }

        h3 {
            transition: $global-opacity-transition;
        }
    }

    &:hover {
        h3 {
            opacity: $global-opacity-knock-back;
        }
    }

    .filler-link {
        z-index: 10;
    }

    .article-link__category {
        opacity: 0;
    }

    &.current {
        z-index: 12;

        .article-link__category {
            opacity: 1;
        }
    }
}

.quick-links__title {
    margin-bottom: rem(24);
    border-bottom: 1px solid $dark-blue;

    .invert-page & {
        border-bottom-color: white;
    }
}

.quick-link {
    position: relative;
    color: white;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1.5rem;
    padding: 1.5rem;
    transition: opacity 1s ease-in-out;
    transition-delay: 0.6s;

    &:nth-of-type(1) {
        transition-delay: 0s;
    }

    &:nth-of-type(2) {
        transition-delay: 0.2s;
    }

    &:nth-of-type(3) {
        transition-delay: 0.4s;
    }

    .easing-contents & {
        opacity: 0;
    }

    .ease-in & {
        opacity: 1;
    }

    .module__bg-image {
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &__inner {
        position: relative;
    }

    &__title {
        margin: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .video-icon {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
    }

    &__border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: $coral;
        transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &__rollover {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        width: 100%;
        background: rgba(230, 105, 123, 0.6);
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
    }

    &:hover {
        .module__bg-image {
            /* transform: scale(1.05); */
        }

        .quick-link__title {
            /* transform: translateY(-12px); */
            opacity: 0;
        }

        .quick-link__border {
            /* transform: translateY(-20px);  */
        }

        .quick-link__rollover {
            /* opacity: 0.6; */
        }
    }
}

.hovers .rollover-trigger:hover .quick-link__rollover,
.quick-link__rollover.reveal {
    opacity: 1;
}

.big-media {
    padding: rem(48) 0;
}

.featured-video {
    position: relative;

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__info {
        margin-bottom: rem(24);
    }

    &__title {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        justify-content: flex-end;
        color: white;

        &-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: $global-opacity-transition;
        }
    }

    &__container {
        position: relative;

        &:hover {
            .featured-video__title-inner {
                opacity: $global-opacity-knock-back;
            }
        }
    }
}

.sitemap {
    ul {
        list-style-type: none;
        padding: 0;

        &.ul-top {
            li.li-top {
                &:before {
                }
            }
        }
    }

    h3 {
    }

    li {
        padding-left: 0;
        margin: 3rem 0 2rem;
    }

    ul.ul-sub {
        margin-top: 2rem;

        li {
            margin-left: 2rem;
            margin-top: 0;
        }
    }

    ul.ul-sub-sub {
        // margin: 2rem;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        li {
            margin: 1rem 2rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &.reveal {
            height: auto;
            opacity: 1;
        }
    }

    h3 {
        font-size: rem($med-title-size);
        line-height: rem($med-line-height);
        @include use-big-caslon;
    }

    a {
        transition: $global-opacity-transition;

        &.see-more {
            opacity: 0.6;
            float: right;
        }

        &:hover {
            opacity: $global-opacity-knock-back;
        }
    }
}

@media (max-width: $b-p-desktop - 1) {
    .nav {
        font-size: rem($pg-size-small);

        &.home {
            .site-title {
                padding: 3rem 1.5rem;
                justify-content: center;
            }
        }
    }

    .hide-mobile {
        display: none;
    }

    .menu {
        &__column {
            & + & {
                padding-top: rem(24);
            }
        }

        &__divider {
            border-bottom: 1px solid white;

            .invert-page & {
                border-bottom-color: $dark-blue;
            }
        }
    }

    .post-feed,
    .search__results,
    .featured-video__sizing,
    footer .standard-page-gutter {
        max-width: 692px;
        margin-left: auto;
        margin-right: auto;
    }

    .text-area,
    .text-area__title,
    .section-title:not(.has-categories) .quick-description,
    .audio-player-embed {
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .featured-video__container.fullwidth {
        margin-top: rem(36);
    }

    .article-link__wide-block.no-image {
        padding: 8rem 4rem 6rem;
    }
}

@media (max-width: 400px) {
    .nav__list {
        font-size: 3.5vw;

        li {
            margin: 0 0.5rem;
        }
    }
}

@media (min-width: 620px) {
    .standard-page-gutter {
        &:not(.full-mobile) {
            padding: 0 rem(36);
            margin-left: auto;
            margin-right: auto;
        }
    }

    .nav__live-category {
        padding: 1rem rem(36);
    }

    .mobile-only {
        display: none;
    }

    .tablet-only {
        display: block;
    }

    .homepage__slide {
        &-content {
            padding: 0 rem(36);

            h2 {
                font-size: rem($large-title-size);
                line-height: rem($double-line-height);
            }

            p,
            a {
                font-size: rem(20);
                line-height: rem(24);
            }
        }
    }

    .carousel-nav {
        &__outer {
            .carousel__container--home & {
                padding: 0 rem(36);
            }
        }
    }
}

@import 'desktop';
@import 'widescreen';
