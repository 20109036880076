$font-size-body: 20;
$pg-size-small: 16;
$pg-size-large: 34;
$blockquote-size: 28;
$med-title-size: 40;
$large-title-size: 64;
$large-line-height: 72;

@media (min-width: $b-p-widescreen) {
    body {
        @include use-body-font;
    }

    blockquote {
        font-size: rem($blockquote-size);
        line-height: rem($med-line-height);
    }

    .small-font,
    pre {
        @include reset-small-font;
    }

    .large-font {
        @include reset-large-font;
        line-height: rem($med-line-height);
    }

    .med-title {
        font-size: rem($med-title-size);
        line-height: rem($double-line-height);
    }

    .large-title {
        font-size: rem($large-title-size);
        line-height: rem($large-line-height);
    }

    .text-area {
        h3 {
            font-size: rem($med-title-size);
            line-height: rem($double-line-height);
        }

        blockquote {
            padding: rem(96) rem(60);
        }
    }

    .article__share {
        top: rem(36);
    }

    .homepage__slide {
        &-content {
            h2 {
                font-size: rem(64);
                line-height: rem(72);
            }
        }
    }
}
